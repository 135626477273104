<template>
	<div class="pui-form">
		<mcontacto-modals
			:modelName="modelName"
		></mcontacto-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mcontacto-form-header
				:modelPk="modelPk"
			></mcontacto-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDCONTACTO -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idcontacto-mcontacto`"
									v-model="model.idcontacto"
									:label="$t('mcontacto.idcontacto')"									
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NOMBRE -->
							<v-flex xs12 md8>
								<pui-text-field
									:id="`nombre-mcontacto`"
									v-model="model.nombre"
									:label="$t('mcontacto.nombre')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-flex>							
						</v-layout>
						<v-layout wrap>							
							<!-- NIF -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`nif-mcontacto`"
									v-model="model.nif"
									:label="$t('mcontacto.nif')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="17"
								></pui-text-field>
							</v-flex>
							<!-- NUMERO -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`numero-mcontacto`"
									v-model="model.numero"
									:label="$t('mcontacto.numero')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="25"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- IDPUERTOLOCAL -->
							<v-flex xs12 md4>								
								<pui-select
									id="idpuertolocal"
									attach="idpuertolocal"
									:label="$t('mcontacto.idpuertolocal')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmpuertolocal"
									:itemsToSelect="[{ id: model.idpuertolocal }]"
									:modelFormMapping="{ id: 'idpuertolocal' }"
									itemValue="id"
									itemText="descripcion"
									:order="{ descripcion: 'asc' }"
								></pui-select>
							</v-flex>							
						</v-layout>						
					</pui-field-set>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-flex>
			</v-layout>
			

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mcontactoActions from './McontactoActions';
import mcontactoModals from './McontactoModals.vue';

export default {
	name: 'mcontacto-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mcontacto-modals': mcontactoModals
	},
	data() {
		return {
			modelName: 'mcontacto'
			, actions: mcontactoActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mcontacto');
		}
	},
	created() {
	}
}
</script>
